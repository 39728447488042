<template>
    <v-item-group multiple>
        <v-container>
            <v-row>
                <v-col 
                    v-for="(item, idx) in machines" 
                    :key="idx" 
                    cols="12"
                    sm="6"
                    md="6"
                    class="pa-1"
                >
                    <v-item>
                        <v-card
                            :color="getColor(item.state)"
                            class="d-flex align-center"
                            dark
                            height="50"
                            @click="setMachineState(item)"
                        >
                            <v-scroll-y-transition>
                                <div class="flex-grow-1 text-center">
                                    <p class="text-body-2 ma-0">
                                        {{ item.name }}
                                    </p>
                                </div>
                            </v-scroll-y-transition>
                        </v-card>
                    </v-item>
                </v-col>
            </v-row>
        </v-container>
    </v-item-group>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: "MachineToggle",
    // props: {
    //     machines: {
    //         type: Array,
    //         required: true,
    //     },
    // },
    components: {},

    data() {
        return {};
    },

    computed: {
        ...mapState({
            machines: (state) => state.sim.machineList,
        })
    },

    methods: {
        setMachineState(item) {
            item.state = !item.state
        },

        getColor(state){
            if (state) return 'success'
            return 'warning'
        }
    },
};
</script>
