<template>
    <v-card>
        <v-card-title class="mb-8">
            <v-btn 
                icon 
                class="side_menu__close_guide" 
                @click="closeGuide()"
            >
                <v-icon color="primary">
                    fas fa-times
                </v-icon>
            </v-btn>
        </v-card-title>

        <v-card-text class="pt-10">
            <v-row align="center">
                <v-col cols="4">
                    <v-item-group mandatory>
                        <v-container>
                            <v-row>
                                <v-col
                                    v-for="n in 2"
                                    :key="n"
                                    class="pr-0 pb-0"
                                >
                                    <v-item v-slot="{ active }">
                                        <v-card
                                            :color="
                                                active ? 'success' : 'error'
                                            "
                                            class="d-flex align-center"
                                            dark
                                            height="90"
                                        >
                                            <div
                                                class="text-body-1 flex-grow-1 text-center"
                                            >
                                                M-{{ n }}
                                            </div>
                                        </v-card>
                                    </v-item>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-item-group>
                </v-col>
                <v-col>
                    <p class="ma-0">
                        {{ $t("Guide.machines") }}
                    </p>
                </v-col>
            </v-row>
            <v-row 
                align="center"
                class="mt-8"
            >
                <v-col 
                    cols="4" 
                    class="d-flex justify-center"
                >
                    <v-card 
                        color="success"
                        width="80"
                    >
                        <v-card-title class="justify-center">
                            <v-icon color="white">
                                fas fa-fan fa-spin
                            </v-icon>
                        </v-card-title>
                        <v-card-text class="d-flex justify-center">
                            <p class="ma-0 white--text">
                                {{$t("Simulation.vent.mode")}}
                            </p>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col> {{ $t("Guide.vent") }} </v-col>
            </v-row>

            <v-row 
                align="center" 
                class="mt-8"
            >
                <v-col 
                    cols="4" 
                    class="text-center"
                >
                    <v-icon size="40">
                        fas fa-user
                    </v-icon>
                </v-col>
                <v-col>
                    {{ $t("Guide.people") }}
                </v-col>
            </v-row>

            <v-row 
                align="center" 
                class="mt-8"
            >
                <v-col cols="4">
                    <v-card>
                        <v-card-text class="clock__container">
                            <v-icon>fas fa-clock</v-icon>
                            <p>{{ $t("Home.SideMenu.from") }}</p>
                            <p>{{ $t("Home.SideMenu.to") }}</p>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col>
                    {{ $t("Guide.time") }}
                    <p class="text-caption ma-0 text--secondary">
                        {{ $t("Guide.timeSub") }}
                    </p>
                </v-col>
            </v-row>

            <v-row 
                align="center" 
                class="mt-8 pb-10"
            >
                <v-col 
                    cols="4" 
                    class="text-center"
                >
                     <v-btn
                        color="primary"
                        large
                        depressed
                    >
                        {{ $t("Home.SideMenu.calculateBtn") }}
                    </v-btn>
                </v-col>
                <v-col>
                    {{ $t("Guide.calculate") }}
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: "Guide",

    props: {
        show: {
            type: Boolean,
            required: true,
        },
    },

    methods: {
        closeGuide() {
            this.$emit("update:show", false);
        },
    },
};
</script>

<style scoped>
.side_menu__guide {
    height: 30%;
}

.side_menu__close_guide {
    position: absolute;
    z-index: 10;
    right: 5px;
    top: 5px;
}

.clock__container{
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
</style>
