<template>
    <v-app>
        <layout></layout>
    </v-app>
</template>

<script>
//Component Imports
import Layout from "./layout/Layout.vue"

export default {
    name: "App",

    components: {
        Layout,
    },

    data: () => ({
        //
    }),
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');
@import url("./styles/common.scss");

</style>
