import mutations    from './mutations.js';
import getters      from './getters.js';
import actions      from './actions.js';


const state = {
  access_token: localStorage.getItem("menior_token") || "",
  userRole: localStorage.getItem("menior_factory") || "",
  userId: localStorage.getItem("menior_user_id") || "",
  
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};