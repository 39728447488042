<template>
    <div 
        class="sensor__container"
        @mouseover="mouseOnTarget = true"
        @mouseleave="mouseOnTarget=false"
    >
        <transition name="fade">
            <v-icon
                v-show="mouseOnTarget"
                color="modalText"
                class="sensor__info"
            >   
                fas fa-info-circle
            </v-icon>
        </transition>
        <v-icon 
            :color="getColor"
            size="45"
        >
            fas fa-circle
        </v-icon>
        <img 
            :src="require('../../assets/sensor.png')" 
            width="35" 
            height="35"
            class="sensor__image"
        >
    </div>
</template>


<script>
import {getAQColor} from "../../helpers/AQindexes";

export default {
    name: "SensorIcon",

    props: {
        index: {
            type: Number,
            required: false,
            default: 0,
        },
    },

    data(){
        return {
            mouseOnTarget: false,
        }
    },

    computed: {
        getColor(){
            return getAQColor(this.index);
        }
    }


}
</script>

<style scoped>
.sensor__container{
    position: relative;
}
.sensor__image{
    z-index: 1;
    position: absolute;
    right: 5px;
    top: 4px;
}

.sensor__info{
    position: absolute;
    top: -10px;
    right: -5px;
    z-index: 20;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

i::before{
    background: white;   
    border-radius: 50%;
}
</style>