<template>
    <v-select
        v-model="selectedValue"
        :items="items"
        :label="$t('Home.Dropdown.title')"
        outlined
        rounded
        class="dropdown__select"
        @change="handleChange($event)"
    ></v-select>
</template>

<script>

export default {
    name: "Dropdown",

    props: {
        data: {
            type: String,
            required: true,
        }
    },

    data(){
        return {
            items: [this.$t("Home.Dropdown.aqi"), this.$t("Home.Dropdown.tci")],
            selectedValue:this.$t(`Home.Dropdown.${this.data}`)
        }
    },

    methods: {
        handleChange(event){
            if(event === this.items[0]) {
                this.$store.dispatch("sensors/setMode", "aqi");
                return
            }

            this.$store.dispatch("sensors/setMode", "tci");
        },
    },

}
</script>

<style scoped>

.dropdown__select{
    position: absolute;
    top: 30px;
    right: 10px;
    width: 220px;
    min-width: 220px;
}
</style>