import mutations    from './mutations.js';
import getters      from './getters.js';
import actions      from './actions.js';

const state = {
        sensorsData: [],
        mode: "aqi",
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};