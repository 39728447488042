import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index"
import routes from "./routes"

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

/**
* Before each route renders, set its title to the page's title
* After that check it's meta tags for requiresAuth value.
* If there is one it checks if the user is logged. If he is logged it lets him navigate
* to the wanted page, else it redirects him to the login page.
* 
* If there is no meta tag it lets him navigate to the wanted page
*/

router.beforeEach( (to, from, next) => {
    document.title = `${to.name} - MENIoR`;
    next();

    if (to.matched.some(record => record.meta.requiresAuth)){
        if( store.getters["auth/isLogged"]){
            next()
        }else {
            next("/login")
        }
    }else {
        next()
    }
});

export default router;
