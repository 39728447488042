const setMachineList = ({commit, state}, payload) => {
    if(state.machineList.length !== payload.length){
        commit("setMachineList", payload)
    }
}

const setSimData = ({commit}, payload) => {
    commit("setSimData", payload)
}

const setSensorsList = ({commit}, payload) => {
    commit("setSensorsList", payload)
}

const setTime = ({commit}, payload) => {
    commit("setTime", payload)
}

const setPeopleOnShift = ({commit}, payload) => {
    commit("setPeopleOnShift", payload)
}

const setVentilation = ({commit}, payload) => {
    commit("setVentilation", payload)
}

const clearData = ({commit}) => {
    commit("clearData")
}

const initTime = ({commit}) => {
    const hours = new Date().getHours();
        let minutes = new Date().getMinutes();
        if (minutes < 10) minutes = `0${minutes}`;

        const fromTime = `${hours}:${minutes}`;

        let toMinutes = (minutes + 10) % 60; //modulo 60 to not exceed 60
        let toHours = hours;
        if (toMinutes < 10) {
            toMinutes = `0${toMinutes}`;
            toHours = toHours + 1;
        }
        const toTime = `${toHours}:${toMinutes}`;

    const initialTime = {
        from: fromTime,
        to: toTime,
    }
    commit("setTime", initialTime)
}


  export default{
      setMachineList,
      setTime,
      setPeopleOnShift,
      setVentilation,
      initTime,
      setSimData,
      setSensorsList,
      clearData
  }