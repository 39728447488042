import mutations    from './mutations.js';
import getters      from './getters.js';
import actions      from './actions.js';

const state = {
                machineList: [],
                sensorsList: [],
                time: {
                    from: "",
                    to: "",
                },
                peopleOnShift: 0,
                ventilation: 0,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};