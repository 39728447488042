<template>
<v-row class="chart__container">
    <v-col 
        cols="12"
        class="text-center"
    >
        <v-chart
            class="chart" 
            :option="chartOptionsLine"
            :autoresize="true"
        ></v-chart>
    </v-col>
</v-row>
</template>

<script>
import * as echarts from 'echarts/core';
import { GridComponent, TitleComponent, TooltipComponent, DataZoomComponent} from 'echarts/components';
import { LineChart } from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import VChart from "vue-echarts";

echarts.use([GridComponent, LineChart, CanvasRenderer, UniversalTransition, TitleComponent, TooltipComponent, DataZoomComponent]);


export default {
  name: "SensorChart",

  props: {
      pollutant: {
          type: String,
          required: false,
          default: 'PM2.5'
      },

      values: {
          type: Array,
          required: true,
      },
      dates: {
          type: Array,
          required: true,
      },
  },
  components: {
    VChart
  },
    data() {
        return {
            chartOptionsLine: {
                title: {
                    text: this.$t("SensorDialog.MainContent.threeDays"),
                    x:'center'
                },
                tooltip: {
                    trigger: 'axis',
                    formatter: ' {a}: {c} μg/m <sup>3</sup> </br> {b} '
                },
                color: ['rgba(22, 182, 233, 0.4)', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'],
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: this.dates
                },
                yAxis: {
                    type: 'value'
                },
                dataZoom: [
                    {
                    type: 'inside',
                    start: 90,
                    end: 100
                    },
                    {
                    start: 90,
                    end: 100
                    }
                ],
                series: [
                    {
                        name: this.pollutant,
                        data: this.values,
                        type: 'line',
                        smooth: true,
                        areaStyle: {},
                    }
                ]
            },

        }
    },
    watch: {
        values(newVal){
            this.chartOptionsLine.series[0].data = newVal;
        },

        dates(newVal){
            
            this.chartOptionsLine.xAxis.data = newVal;
        },


    },
};
</script>

<style scoped>
.chart {
  height: 300px;
  width: 100%;
}
</style>