const changeData = ( { commit }, payload) => {
    commit("changeData", payload)
}

const setSensorsData = ({commit}, payload) => {
    commit("setSensorsData", payload)
}

const setMode = ({commit}, payload) => {
    commit("setMode", payload)
}

const clearSensors = ({commit}) => {
    commit("clearSensors")
}

  export default{
      changeData,
      setSensorsData,
      setMode,
      clearSensors
  }