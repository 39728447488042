const setToken = (state, payload) => state.access_token = payload;
const removeToken = state => state.access_token = ""

const setUserRole = (state, payload) => state.userRole = payload;
const removeUserRole = (state) => state.userRole = null;

const setUserId = (state, payload) => state.userId = payload;
const removeUserId = (state) => state.userId = null;

export default {
    setToken,
    removeToken,
    setUserRole,
    removeUserRole,
    setUserId,
    removeUserId,
}