<template>
    <div class="legend__container">
        <div class="legend_left flex__center">
            <div class="flex__center mr-2">
                <img 
                    :src="require('../assets/legend/sensor.png')" 
                    width="50"
                >
                <p>{{ $t("Legend.sensor") }}</p>
            </div>
            <div class="flex__center pt-1">
                <img
                    :src="require('../assets/legend/machines.png')"
                    width="50"
                >
                <p>{{ $t("Legend.machine") }}</p>
            </div>
        </div>
        <div class="legend_right">
            <div class="legend_right__title flex__center mb-3">
                <p class="font-weight-medium">
                    {{ $t(`${sensorMode}.title`) }}
                </p>
            </div>
            <div class="legend_right__content flex__center">
                <div
                    v-for="item in aqData"
                    :key="item.aqi"
                    class="d-flex pa-0 ma-0 mr-2"
                >
                    <v-icon :color="item.color">
                        fas fa-circle
                    </v-icon>
                    <p class="ml-1">
                        {{ $t(`${sensorMode}.${item.aqi}`) }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex"
export default {
    name: "TheLegend",

    data() {
        return {
            aqData: [
                { aqi: "verygood", color: "aqgreen" },
                { aqi: "good", color: "aqblue" },
                { aqi: "medium", color: "aqyellow" },
                { aqi: "bad", color: "aqred" },
            ],
        };
    },

    computed: {
        ...mapState({
            sensorMode: (state) => state.sensors.mode,
        }),
    },
};
</script>

<style scoped>
p {
    margin: 0;
}

.legend__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0% 5%;
    margin-top: 5%;
    font-size: 0.85rem;
}

.flex__center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.legend_right__content{
    
    max-width: 400px;
}
</style>
