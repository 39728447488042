<template>
    <v-dialog v-model="show" width="400" min-width="300" persistent>
        <v-card class="px-10 py-6">
            <v-card-title class="justify-center">
                {{ $t("Home.SideMenu.timeSelect") }}
            </v-card-title>
            <v-card-text>
                <v-menu
                    ref="menuFrom"
                    v-model="fromMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="fromTime"
                            :label="$t('Home.SideMenu.from')"
                            prepend-icon="fas fa-clock"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                    </template>
                    <v-time-picker
                        v-if="fromMenu"
                        v-model="fromTime"
                        full-width
                        @click:minute="$refs.menuFrom.save(fromTime)"
                    ></v-time-picker>
                </v-menu>
                <v-menu
                    ref="menuTo"
                    v-model="toMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="toTime"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="toTime"
                            :label="$t('Home.SideMenu.to')"
                            prepend-icon="fas fa-clock"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                    </template>
                    <v-time-picker
                        v-if="toMenu"
                        v-model="toTime"
                        full-width
                        @click:minute="$refs.menuTo.save(toTime)"
                    ></v-time-picker>
                </v-menu>
            </v-card-text>
            <v-card-actions class="justify-end">
                <v-btn color="primary" outlined @click="resetTime()">
                    {{ $t("General.reset") }}
                </v-btn>
                <v-btn color="primary" depressed @click="closeDialog()">
                    {{ $t("General.ok") }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "TimePicker",
    props: {
        show: {
            type: Boolean,
            required: true,
        },

        time: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            fromTime: this.time.from,
            toTime: this.time.to,
            fromMenu: false,
            toMenu: false,
        };
    },

    methods: {
        closeDialog() {
            this.$emit("update:show", false);

            this.$store.dispatch("sim/setTime", {
                from: this.fromTime,
                to: this.toTime,
            });
        },
        resetTime() {
            this.$store.dispatch("sim/initTime");
        },
    },

    watch:{
        time(newVal){
            this.fromTime = newVal.from;
            this.toTime = newVal.to;
        }
    }
};
</script>
