import Vue          from "vue";
import Vuex         from "vuex";
import auth         from "./modules/auth";
import socket      from "./modules/socket";
import sensors      from "./modules/sensors"
import sim      from "./modules/sim"
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    socket,
    sensors,
    sim,
  },
});
