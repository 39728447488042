<template>
    <v-app>
        <router-view name="navbar"></router-view>
        <v-main>
            <v-container fluid class="fill-height">
                <router-view></router-view>
            </v-container>
        </v-main>
    </v-app>
</template>
<script>
export default {
    name: "TheLayout",
};
</script>

<style scoped></style>
