<template>
    <v-item-group>
        <v-row>
            <v-col
                v-for="state in ventStates"
                :key="state.value"
                cols="4"
                md="4"
            >
                <v-item>
                    <v-card
                        :color="
                            state.value === ventilation
                                ? 'success'
                                : ''
                        "
                        @click="
                            setVentilation(state.value)
                        "
                    >
                        <v-card-title
                            class="justify-center"
                        >
                            <v-icon
                                :color="
                                    state.value ===
                                    ventilation
                                        ? 'white'
                                        : 'primary'
                                "
                                :class="state.animation"
                            >
                                {{
                                    ventIcon(
                                        state.value
                                    )
                                }}
                            </v-icon>
                        </v-card-title>
                        <v-card-text
                            class="d-flex justify-center"
                        >
                            <p
                                class="ma-0"
                                :class="
                                    state.value ===
                                    ventilation
                                        ? 'white--text'
                                        : ''
                                "
                            >
                                {{ state.text }}
                            </p>
                        </v-card-text>
                    </v-card>
                </v-item>
            </v-col>
        </v-row>
    </v-item-group>
</template>

<script>
import {mapState} from "vuex"
export default {
    name: "Ventilation",

    data(){
        return {
            ventStates: [
                { value: 0, text: this.$t("Simulation.vent.off"), animation: "" },
                { value: 1, text: this.$t("Simulation.vent.medium"), animation: "rotate_slow" },
                { value: 2, text: this.$t("Simulation.vent.full"), animation: "rotate_fast" },
            ],
        }
    },

    computed: {
        ...mapState({
            ventilation: (state) => state.sim.ventilation,
        }),
    },

    methods: {
         ventIcon(value) {
            if (value === 0) {
                return "fas fa-fan";
            } else if (value === 1) {
                return "fas fa-fan";
            }
            return "fas fa-fan fa-spin";
        },

        setVentilation(value) {
            this.$store.dispatch("sim/setVentilation",value);
        },
    }
}
</script>

<style scoped>

.rotate_fast {
    animation: rotate_fast 0.5s infinite linear;
}

.rotate_slow {
    animation: rotate_fast 2s infinite linear;
}

@keyframes flash {
    0% {
        opacity: 1;
    }
    20% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}
@keyframes rotate_fast {
    0% {
        opacity: 1;
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>