<template>
    <v-container class="toggle__container px-4 py-0 ma-0">
        <v-radio-group
            :value="data"
            @change="handleChange"
        >
            <v-radio
                value="live" 
                :label="$t('Home.Toggle.liveData')"
            >
            </v-radio>
            <v-radio
                value="sim"
                :label="$t('Home.Toggle.simulated')"
                :disabled="disabledSimulation"
            >
            </v-radio>
        </v-radio-group>
    </v-container>
</template>
    

<script>
export default {
    name: "Toggle",

    props: {
        data: {
            type: String,
            required: true,
        },
        disabledSimulation: {
            type: Boolean,
            required: false,
            default: false,
        }
    },

    data(){
        return{
        }
    },

    methods: {
        handleChange(event){
            this.$emit('update:data', event);
        }
    },
}
</script>

<style scoped>

.toggle__container{
    position: absolute;
    top: 64px;
    width: 140px
}
</style>