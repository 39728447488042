<template>
    <v-container class="register__container">
        <v-card class="register_form__container py-10 px-sm-5 text-center">
            <v-card-title>
                <v-row>
                    <v-col cols="12">
                        <p
                            class="primary--text font-weight-medium text-h4 pb-5"
                        >
                            MENIoR
                        </p>
                        <p class="text-center text--secondary text-body-1">
                            {{ $t("Register.title") }}
                        </p>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-form @submit.prevent="register()">
                <v-card-text>
                    <v-text-field
                        v-model="email"
                        :error-messages="emailErrors"
                        type="text"
                        required
                        outlined
                        :label="$t('Register.fields.name')"
                        @input="handleEmailInput($event)"
                        @blur="$v.email.$touch()"
                    ></v-text-field>
                    <v-text-field
                        v-model.trim="password"
                        :error-messages="passwordErrors"
                        :append-icon="
                            showPassword ? 'fas fa-eye' : 'fas fa-eye-slash'
                        "
                        :type="showPassword ? '' : 'password'"
                        required
                        outlined
                        :label="$t('Register.fields.password')"
                        @input="handlePasswordInput($event)"
                        @blur="$v.password.$touch()"
                        @click:append="showPassword = !showPassword"
                    ></v-text-field>
                    <v-text-field
                        v-model.trim="passwordConfirmation"
                        :error-messages="passwordConfirmationErrors"
                        :append-icon="
                            showPasswordConf ? 'fas fa-eye' : 'fas fa-eye-slash'
                        "
                        :type="showPasswordConf ? '' : 'password'"
                        required
                        outlined
                        :label="$t('Register.fields.passwordConf')"
                        @input="handlePasswordConfInput($event)"
                        @blur="$v.passwordConfirmation.$touch()"
                        @click:append="showPasswordConf = !showPasswordConf"
                    ></v-text-field>
                    <v-select
                        v-model="selectedIndustry"
                        :items="industries"
                        label="Industry"
                        :error-messages="industryErrors"
                        outlined
                        @input="handleIndustryInput($event)"
                        @blur="$v.selectedIndustry.$touch()"
                    ></v-select>
                </v-card-text>
                <v-card-actions class="d-flex flex-column">
                    <v-alert
                        :value="showErrorAlert"
                        type="error"
                        dense
                        transition="slide-x-transition"
                    >
                        {{ errorAlertText }}
                    </v-alert>
                    <v-btn
                        type="submit"
                        class="text-button"
                        color="primary"
                        depressed
                        :disabled="formNotValid"
                        :loading="registerStatus_Pending"
                    >
                        {{ $t("Register.fields.registerBtn") }}
                    </v-btn>
                    <router-link to="/login">
                        <p class="black--text text-center text--disabled mt-5">
                            {{ $t("Register.alreadyHas") }}
                        </p>
                    </router-link>
                </v-card-actions>
            </v-form>
        </v-card>
         <div class="eu_image__container">
            <img :src="require('../../assets/eu.png')" class="eu_image">
        </div>
        <div class="background background_shape">
            <img
                :src="require('../../assets/background.jpg')"
                class="background background_image"
            >
        </div>
        <v-dialog 
            v-model="showVerifyDialog" 
            max-width="500"
        >
            <v-card>
                <v-card-title> Verify your account </v-card-title>
                <v-card-text>
                    We have sent an E-mail with the verification link to the
                    address you entered. Please verify it to continue.
                </v-card-text>
                <v-card-actions class="justify-end">
                    <v-btn
                        depressed
                        color="primary"
                        @click="closeVerifyDialog()"
                    >
                        Ok
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
// API layer Imports
import { withAsync } from "../../helpers/withAsync";
import { registerUser } from "../../api/authApi.js";
import { apiStatus } from "../../api/constants/apiStatus.js";
import { apiStatusComputed } from "../../api/helpers/computedApiStatus.js";
import { apiObject } from "../../api/api";

// Vuelidate Imports
import { validationMixin } from "vuelidate";
import { required, minLength, email, sameAs } from "vuelidate/lib/validators";

import { getIndustry } from "../../helpers/factories.js";

export default {
    name: "Login",

    mixins: [validationMixin],

    validations: {
        email: {
            required,
            email,
        },
        password: {
            required,
            minLengthValue: minLength(6),
        },
        passwordConfirmation: {
            required,
            sameAsPassword: sameAs("password"),
        },
        selectedIndustry: {
            required,
        },
    },

    data() {
        return {
            email: "",
            password: "",
            passwordConfirmation: "",
            industries: ["Lisanplast, S.L.", "Fagesta Steel"],
            selectedIndustry: "",

            showPassword: false,
            showPasswordConf: false,

            registerStatus: apiStatus.Idle,

            showErrorAlert: false,
            errorAlertText: "There was an error",

            showVerifyDialog: false,
        };
    },

    computed: {
        ...apiStatusComputed("registerStatus"),

        /**
         * Following functions are used to validate the form on touch and blur
         * @summary Using vuelidate we can check for errors using vuelidate mixins and validations
         * @return {Array} returns array with errors to be shown under the text fields.
         */

        emailErrors() {
            const errors = [];
            if (!this.$v.email.$dirty) return errors;
            !this.$v.email.email &&
                errors.push(this.$t("ErrorMessages.validEmail"));
            !this.$v.email.required &&
                errors.push(this.$t("ErrorMessages.required"));
            return errors;
        },

        passwordErrors() {
            const errors = [];
            if (!this.$v.password.$dirty) return errors;
            !this.$v.password.minLengthValue &&
                errors.push(this.$t("ErrorMessages.minLength"));
            !this.$v.password.required &&
                errors.push(this.$t("ErrorMessages.required"));
            return errors;
        },

        passwordConfirmationErrors() {
            const errors = [];
            if (!this.$v.passwordConfirmation.$dirty) return errors;
            !this.$v.passwordConfirmation.required &&
                errors.push(this.$t("ErrorMessages.required"));
            !this.$v.passwordConfirmation.sameAsPassword &&
                errors.push(this.$t("ErrorMessages.samePassword"));
            return errors;
        },

        industryErrors() {
            const errors = [];
            if (!this.$v.selectedIndustry.$dirty) return errors;
            !this.$v.selectedIndustry.required &&
                errors.push(this.$t("ErrorMessages.required"));
            return errors;
        },

        formNotValid() {
            return this.$v.$invalid;
        },
    },

    methods: {
        /**
         * Sends a call to the backend holding the needed data as payload.
         * If the credentials are valid it sets the Authorization header
         * to the access_token and dispatches 3 actions of the the vuex store.
         * The first sets the token in the global state and the localStorage.
         * The second sets the user_role in the global state and the localStorage.
         * The third sets the user_id in the global state and the localStorage.
         * At last redirects the user to the home page
         */

        async register() {
            this.registerStatus = apiStatus.Pending
            const payload = {
                email:      this.email,
                password:   this.password,
                password2: this.passwordConfirmation,
                factory: getIndustry(this.selectedIndustry)
            };


            const { response, error } = await withAsync(registerUser, payload);

            if(error) {
                this.registerStatus = apiStatus.Error;
                this.showErrorAlert = true
                if(error.response.status === 500){
                    this.errorAlertText = error.response.statusText
                    return
                }
                this.errorAlertText = error.response.data.message || error.response.data;
                return
            }

            this.showVerifyDialog = true;
            this.registerStatus = apiStatus.Success;
        },

        /**
         * Handle text field inputs.
         * Hide error alert when user types and touch the input for vuelidate
         */

        handleEmailInput() {
            this.showErrorAlert = false;
            this.$v.email.$touch();
        },

        handlePasswordInput() {
            this.showErrorAlert = false;
            this.$v.password.$touch();
        },

        handlePasswordConfInput() {
            this.showErrorAlert = false;
            this.$v.passwordConfirmation.$touch();
        },

        handleIndustryInput() {
            this.showErrorAlert = false;
            this.$v.selectedIndustry.$touch();
        },

        closeVerifyDialog() {
            this.showVerifyDialog = false;
            this.$router.push("/login");
        },
    },

    created() {
        this.apiStatus = apiStatus;
    },
};
</script>

<style scoped>
.register__container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.register_form__container {
    width: 100%;
    max-width: 550px;
    z-index: 10;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
        rgba(0, 0, 0, 0.22) 0px 15px 12px;
    border-radius: 15px;
}

.background_shape {
    background-image: linear-gradient(
        to left top,
        #07a398,
        #009fa6,
        #009ab2,
        #0093bb,
        #008cc0,
        #3092c6,
        #4798cb,
        #599ed1,
        #80b5dc,
        #a5cce6,
        #cae2f2,
        #f0f9fe
    );
}

@media screen and (min-width: 768px) {
    .background_shape {
        clip-path: polygon(0 0, 100% 0, 100% 76%, 71% 92%, 37% 78%, 0 100%);
    }
}

@media screen and (max-height: 700px) {
    .register__container {
        height: 100%;
        width: 100%;
    }
}

.background_image {
    opacity: 0.4;
}

.eu_image__container{
    position: static;
    z-index: 20;
}
.eu_image{
    height: 90px;
    width: 100%;
    object-fit: contain;
}

@media screen and (min-height: 700px) {
    .eu_image__container{
        position: absolute;
        z-index: 20;
        bottom: 10px;
        left: auto;
    }
}
</style>
