const changeData = (state, payload) => {
    const item = state.sensorsData.find(item => {
        if(item.id === payload.sensor_id){
            item.enviIndex = payload.enviIndex
            item.thermalComfort = payload.thermalComfort
        }
    });
}

const setSensorsData = (state, payload) => {
    state.sensorsData = [...payload]
}

const setMode = (state, payload) => {
    state.mode = payload
}

const clearSensors = (state) => {
    state.sensorsData = [];
}

export default {
    changeData,
    setSensorsData,
    setMode,
    clearSensors
}