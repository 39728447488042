<template>
    <v-app-bar 
        app 
        color="primary"
        dark
        height="80"
        fixed
        flat
    >
        <v-row align="center">
            <v-col
                v-show="$vuetify.breakpoint.mdAndUp"
                cols="4"
                class="pa-0"
            >
                <img
                    :src="require('../assets/eu.png')"
                    height="70"
                    class="mt-1"
                >
            </v-col>
            <v-col cols="4" class="text-center">
                <p class="font-weight-bold text-h4 pt-4">MENIoR</p>
            </v-col>
            <v-col cols="8" md="4" class="text-right">
                <router-link
                    :to="{ name: 'Home' }"
                    class="text-decoration-none mx-2"
                >
                    <v-btn
                        :color="isExactRoute('Home') ? 'white' : 'transparent'"
                        depressed
                        :class="
                            isExactRoute('Home')
                                ? 'primary--text'
                                : 'white--text'
                        "
                    >
                        {{ $t("Home.nav") }}
                    </v-btn>
                </router-link>

                <router-link
                    :to="{ name: 'Simulation' }"
                    class="text-decoration-none mx-2"
                >
                    <v-btn
                        :color="
                            isExactRoute('Simulation') ? 'white' : 'transparent'
                        "
                        depressed
                        :class="
                            isExactRoute('Simulation')
                                ? 'primary--text'
                                : 'white--text'
                        "
                    >
                        {{ $t("Simulation.nav") }}
                    </v-btn>
                </router-link>
                <v-btn icon class="mr-4" @click="logout()">
                    <v-icon size="35"> fas fa-power-off </v-icon>
                </v-btn>
            </v-col>
        </v-row>
    </v-app-bar>
</template>

<script>
import { withAsync } from "../helpers/withAsync.js";
import { logoutUser } from "../api/authApi.js";
import { apiStatus } from "../api/constants/apiStatus.js";
import { apiStatusComputed } from "../api/helpers/computedApiStatus.js";
import { mapGetters } from "vuex";

export default {
    name: "TheNavbar",

    data() {
        return {
            logoutStatus: apiStatus.Idle,
        };
    },

    computed: {
        ...apiStatusComputed("logoutStatus"),

        ...mapGetters({
            userIsLogged: "auth/isLogged",
        }),
    },

    methods: {
        /**
         * Sends a call to the backend to logout the user.
         * On success it dispatches 2 vuex store actions (removes token and user role)
         * Then it replaces the current route with login route.
         */

        async logout() {
            this.logoutStatus = apiStatus.Pending;

            // const { response, error } = await withAsync(logoutUser);

            // if(error) {
            //     this.logoutStatus = apiStatus.Error;
            //     return
            // }

            this.logoutStatus = apiStatus.Success;
            this.$store.dispatch("auth/removeToken");
            this.$store.dispatch("auth/removeUserRole");
            this.$store.dispatch("auth/removeUserId");
            this.$store.dispatch("sensors/clearSensors");
            this.$store.dispatch("sim/clearData");
            this.$router.replace("/login");
        },

        isExactRoute(route) {
            return this.$route.name === route;
        },
    },

    created() {
        this.apiStatus = apiStatus;
    },
};
</script>

<style scoped>
.navbar {
    z-index: 50;
}
</style>
