<template>
    <v-container class="forgot__container">
        <v-card class="forgot_form__container py-10 px-sm-5 text-center">
            <v-card-title>
                <v-row>
                    <v-col cols="12">
                        <p
                            class="primary--text font-weight-medium text-h4 pb-5"
                        >
                            MENIoR
                        </p>
                        <p class="text-center text--secondary text-body-1">
                            {{ $t("Forgot.title") }}
                        </p>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-form @submit.prevent="submitEmail()">
                <v-card-text>
                    <v-text-field
                        v-model="email"
                        :error-messages="emailErrors"
                        type="text"
                        required
                        outlined
                        :label="$t('Register.fields.name')"
                        @input="$v.email.$touch()"
                        @blur="$v.email.$touch()"
                    ></v-text-field>
                </v-card-text>
                <v-card-actions class="d-flex flex-column">
                    <v-alert
                        :value="showErrorAlert"
                        type="error"
                        dense
                        transition="slide-x-transition"
                    >
                        {{ errorAlertText }}
                    </v-alert>
                    <v-btn
                        type="submit"
                        class="text-button"
                        color="primary"
                        depressed
                        :disabled="$v.$invalid"
                        :loading="forgotStatus_Pending"
                    >
                        {{ $t("General.submit") }}
                    </v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
        <div class="background background_shape">
            <img
                :src="require('../../assets/background.jpg')"
                class="background background_image"
            >
        </div>
        <v-dialog v-model="showResetDialog" max-width="500">
            <v-card>
                <v-card-title> {{ $t("Forgot.dialogTitle") }} </v-card-title>
                <v-card-text>
                    {{ $t("Forgot.dialog") }}
                </v-card-text>
                <v-card-actions class="justify-end">
                    <v-btn
                        depressed
                        color="primary"
                        @click="closeResetDialog()"
                    >
                        {{ $t("General.ok") }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { withAsync } from "../../helpers/withAsync";
import { forgotPassword } from "../../api/authApi.js";
import { apiStatus } from "../../api/constants/apiStatus.js";
import { apiStatusComputed } from "../../api/helpers/computedApiStatus.js";

import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
export default {
    name: "ForgotPassword",
    mixins: [validationMixin],

    validations: {
        email: {
            required,
            email,
        },
    },

    data() {
        return {
            email: "",
            showErrorAlert: false,
            errorAlertText: "There was an error",
            forgotStatus: apiStatus.Idle,

            showResetDialog: false,
        };
    },

    computed: {
        ...apiStatusComputed("forgotStatus"),
        emailErrors() {
            const errors = [];
            if (!this.$v.email.$dirty) return errors;
            !this.$v.email.email &&
                errors.push(this.$t("ErrorMessages.validEmail"));
            !this.$v.email.required &&
                errors.push(this.$t("ErrorMessages.required"));
            return errors;
        },
    },

    methods: {
        async submitEmail() {
            this.forgotStatus = apiStatus.Pending;
            const payload = {
                email: this.email,
            };

            const { response, error } = await withAsync(
                forgotPassword,
                payload
            );

            if (error) {
                this.forgotStatus = apiStatus.Error;
                const data = error.response.data;

                this.showErrorAlert = true;
                if (error.response.status === 500) {
                    this.errorAlertText = error.response.statusText;
                    return;
                }
                this.errorAlertText =
                    data[Object.keys(data)[0]][0]|| error.response.data;
                return;
            }

            this.forgotStatus = apiStatus.Success;
            this.showResetDialog = true;
        },

        closeResetDialog() {
            this.showResetDialog = false;
            this.$router.replace({ name: "Login" });
        },
    },
};
</script>

<style scoped>
.forgot__container {
    height: 85vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.forgot_form__container {
    width: 80%;
    max-width: 550px;
    z-index: 10;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
        rgba(0, 0, 0, 0.22) 0px 15px 12px;
    border-radius: 15px;
}
.background_shape {
    background-image: linear-gradient(
        to left top,
        #07a398,
        #009fa6,
        #009ab2,
        #0093bb,
        #008cc0,
        #3092c6,
        #4798cb,
        #599ed1,
        #80b5dc,
        #a5cce6,
        #cae2f2,
        #f0f9fe
    );
}

@media screen and (min-width: 768px) {
    .background_shape {
        clip-path: polygon(0 0, 100% 0, 100% 76%, 71% 92%, 37% 78%, 0 100%);
    }
}

@media screen and (max-height: 700px) {
    .forgot__container {
        height: 90%;
    }
}

.background_image {
    opacity: 0.4;
}
</style>
