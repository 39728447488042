<template>
    <v-container class="login__container">
        <v-card class="login_form__container pt-10 pb-0 px-5 text-center">
            <v-card-title>
                <v-row>
                    <v-col cols="12">
                        <p
                            class="primary--text font-weight-medium text-h4 pb-5"
                        >
                            MENIoR
                        </p>
                        <p class="text-center text--secondary text-body-1">
                            {{ $t("Login.title") }}
                        </p>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-form @submit.prevent="login()">
                <v-card-text class="pb-0">
                    <v-text-field
                        v-model="email"
                        :error-messages="emailErrors"
                        :label="$t('Login.fields.email')"
                        required
                        outlined
                        @input="handleEmailInput()"
                        @blur="$v.email.$touch()"
                    ></v-text-field>

                    <v-text-field
                        v-model="password"
                        :append-icon="
                            showPassword ? 'fas fa-eye' : 'fas fa-eye-slash'
                        "
                        :type="showPassword ? '' : 'password'"
                        :error-messages="passwordErrors"
                        :label="$t('Login.fields.password')"
                        required
                        outlined
                        @input="handlePasswordInput"
                        @blur="$v.password.$touch()"
                        @click:append="showPassword = !showPassword"
                    ></v-text-field>
                </v-card-text>
                <v-card-actions class="d-flex flex-column">
                    <v-alert
                        :value="showErrorAlert"
                        type="error"
                        dense
                        transition="slide-x-transition"
                    >
                        {{ errorAlertText }}
                    </v-alert>
                    <v-btn
                        type="submit"
                        class="text-button"
                        color="primary"
                        depressed
                        :disabled="formNotValid"
                        :loading="loginStatus_Pending"
                    >
                        {{ $t("Login.fields.loginBtn") }}
                    </v-btn>
                    <router-link to="/register">
                        <p
                            class="primary--text text--secondary text-center mt-5"
                        >
                            {{ $t("Login.doesntHave") }}
                        </p>
                    </router-link>

                    <router-link to="/forgot-password">
                        <p class="text-center text-caption text--disabled mt-8">
                            {{ $t("Login.forgotPassword") }}
                        </p>
                    </router-link>
                </v-card-actions>
            </v-form>
        </v-card>
        <v-card class="app_card">
            <v-card-text class="pa-0">
                <v-btn
                    :href="`${publicPath}menior.apk`"
                    download
                    height="50"
                    color="#f0f9fe"
                    class="pa-2"
                >
                    <div class="d-flex">
                        <img
                            :src="require('../../assets/ar.png')"
                            height="50"
                        >
                         <span class="d-block mainText--text align-self-center">
                            {{ $t("Login.download") }}
                        </span>
                    </div>
                </v-btn>
            </v-card-text>
        </v-card>
        <div class="eu_image__container">
            <img :src="require('../../assets/eu.png')" class="eu_image">
        </div>

        <div class="background background_shape">
            <img
                :src="require('../../assets/background.jpg')"
                class="background background_image"
            >
        </div>
    </v-container>
</template>

<script>
// API layer Imports
import { withAsync } from "../../helpers/withAsync";
import { loginUser } from "../../api/authApi.js";
import { apiStatus } from "../../api/constants/apiStatus.js";
import { apiStatusComputed } from "../../api/helpers/computedApiStatus.js";
import { apiObject } from "../../api/api";

// Vuelidate Imports
import { validationMixin } from "vuelidate";
import { required, minLength, email } from "vuelidate/lib/validators";

export default {
    name: "Login",

    mixins: [validationMixin],

    validations: {
        email: {
            required,
            email,
        },
        password: {
            required,
            minLengthValue: minLength(6),
        },
    },

    data() {
        return {
            email: "",
            password: "",
            showPassword: false,

            loginStatus: apiStatus.Idle,

            showErrorAlert: false,
            errorAlertText: "There was an error",

            publicPath: process.env.BASE_URL,
        };
    },

    computed: {
        ...apiStatusComputed("loginStatus"),

        /**
         * Following functions are used to validate the form on touch and blur
         * @summary Using vuelidate we can check for errors using vuelidate mixins and validations
         * @return {Array} returns array with errors to be shown under the text fields.
         */

        emailErrors() {
            const errors = [];
            if (!this.$v.email.$dirty) return errors;
            !this.$v.email.email &&
                errors.push(this.$t("ErrorMessages.validEmail"));
            !this.$v.email.required &&
                errors.push(this.$t("ErrorMessages.required"));
            return errors;
        },

        passwordErrors() {
            const errors = [];
            if (!this.$v.password.$dirty) return errors;
            !this.$v.password.minLengthValue &&
                errors.push(this.$t("ErrorMessages.minLength"));
            !this.$v.password.required &&
                errors.push(this.$t("ErrorMessages.required"));
            return errors;
        },

        formNotValid() {
            return this.$v.$invalid;
        },
    },

    methods: {
        /**
         * Sends a call to the backend holding the needed data as payload.
         * If the credentials are valid it sets the Authorization header
         * to the access_token and dispatches 3 actions of the the vuex store.
         * The first sets the token in the global state and the localStorage.
         * The second sets the user_role in the global state and the localStorage.
         * The third sets the user_id in the global state and the localStorage.
         * At last redirects the user to the home page
         */

        async login() {
            this.loginStatus = apiStatus.Pending;
            const payload = {
                email: this.email,
                password: this.password,
            };

            const { response, error } = await withAsync(loginUser, payload);

            if (error) {
                console.log(error.response);
                this.loginStatus = apiStatus.Error;
                this.showErrorAlert = true;
                this.errorAlertText = error.response.data.detail;
                return;
            }
            apiObject.defaults.headers[
                "Authorization"
            ] = `Bearer ${response.data.access}`;

            this.loginStatus = apiStatus.Success;

            const user_factory = response.data.factory.toString();
            const user_id = response.data.user_id;

            this.$store.dispatch("auth/setToken", response.data.access);
            this.$store.dispatch("auth/setUserRole", user_factory);

            this.$router.push("/");
        },

        /**
         * Handle text field inputs.
         * Hide error alert when user types and touch the input for vuelidate
         */

        handlePasswordInput() {
            this.showErrorAlert = false;
            this.$v.password.$touch();
        },

        handleEmailInput() {
            this.showErrorAlert = false;
            this.$v.email.$touch();
        },
    },

    created() {
        this.apiStatus = apiStatus;
    },
};
</script>

<style scoped>
.app_card {
    margin-top: 40px;
    max-width: 550px;
    z-index: 10;
}
.login__container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.login_form__container {
    width: 80%;
    max-width: 550px;
    z-index: 10;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
        rgba(0, 0, 0, 0.22) 0px 15px 12px;
    border-radius: 15px;
}

.background_shape {
    background-image: linear-gradient(
        to left top,
        #07a398,
        #009fa6,
        #009ab2,
        #0093bb,
        #008cc0,
        #3092c6,
        #4798cb,
        #599ed1,
        #80b5dc,
        #a5cce6,
        #cae2f2,
        #f0f9fe
    );
    clip-path: polygon(0 0, 100% 0, 100% 64%, 64% 84%, 24% 70%, 0 90%);
}

@media screen and (min-width: 1080px) {
    .background_shape {
        clip-path: none;
    }
}

@media screen and (max-height: 800px) {
    .login__container {
        height: 100%;
    }
}

.background_image {
    opacity: 0.4;
}

.eu_image__container {
    position: static;
    z-index: 20;
    margin-top: 40px;
}
.eu_image {
    height: 90px;
    width: 100%;
    object-fit: contain;
}

@media screen and (min-height: 700px) {
    .eu_image__container {
        z-index: 20;
    }
}
</style>
