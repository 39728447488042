import Vue          from    "vue";
import App          from    "./App.vue";
import router       from    "./router";
import store        from    "./store";
import socket from "./store/modules/socket";
import vuetify      from    "./plugins/vuetify/index";
import i18n         from    "./plugins/i18n";
import Vuelidate    from    "vuelidate";
import                      "@/plugins/dayjs";
import VueNativeSock from "vue-native-websocket";
import VueGTag from "vue-gtag";

Vue.use(VueGTag, {
    config: {
        id: "G-NJL75NFTQH"
    }
});

Vue.use(VueNativeSock, "wss://websocket.menior.draxis.gr",{
    reconnection: true, // (Boolean) whether to reconnect automatically (false)
    reconnectionAttempts: 5, // (Number) number of reconnection attempts before giving up (Infinity),
    reconnectionDelay: 3000, // (Number) how long to initially wait before attempting a new (1000)
    connectManually: true,
    store: store,
    passToStoreHandler: function (eventName, event) {
        if (!eventName.startsWith('SOCKET_')) { return }
        let method = 'commit'
        let target = eventName.toUpperCase()
        let msg = event
        if (this.format === 'json' && event.data) {
          msg = JSON.parse(event.data)
          if (msg.mutation) {
            target = [msg.namespace || '', msg.mutation].filter((e) => !!e).join('/')
          } else if (msg.action) {
            method = 'dispatch'
            target = [msg.namespace || '', msg.action].filter((e) => !!e).join('/')
          }
        }
        // Write this if multiple stores are used and namespace options are enabled
        target = "socket/" + target
        this.store[method](target, msg)
      }
});
Vue.use(Vuelidate);

Vue.config.productionTip = false;

new Vue({
    name: "MeniorDT",
    router,
    store,
    vuetify,
    i18n,
    render: (h) => h(App),
}).$mount("#app");
