const getToken = state =>  state.access_token;
const isLogged = state => !!state.access_token;

const getUserRole = state => state.userRole;
const userIsAdmin = state => state.userRole === "0";

const getUserId = state => state.userId;



export default {
    getToken,
    isLogged,
    getUserRole,
    userIsAdmin,
    getUserId,
}