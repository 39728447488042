// Component Imports
import Login from "../views/auth/Login.vue";
import Register from "../views/auth/Register.vue"
import Home from "../views/Home.vue"
import Simulation from "../views/Simulation.vue"
import TheNavbar from "../components/TheNavbar.vue"
import ForgotPassword from "../views/auth/ForgotPassword.vue"
import ResetPassword from "../views/auth/ResetPassword.vue"
import Verify from "../views/auth/Verify.vue"
import NotFound from "../views/NotFound.vue"
import SensorDialog from "../views/home/SensorDialog.vue"

//Store Import
import store from "../store/index"

const  routes = [
    {
        path: "/login",
        name: "Login",
        component: Login,

        beforeEnter: (to, from, next) => {
            if(store.getters["auth/isLogged"]){
                next("/");
            } else {
                next();
            }
        }
    },

    {
        path: "/register",
        name: "Register",
        component: Register
    },

    {
        path: "/",
        name: "Home",
        components: {
            default:    Home,
            navbar: TheNavbar
        },

        meta: {
            requiresAuth: true,
        },

        children: [
            {
                path: 'sensor/:id',
                name: 'Sensor Data',
                component:  SensorDialog,
                props: true,
            }
        ]
    },

    {
        path: "/simulation",
        name: "Simulation",
        components: {
            default:    Simulation,
            navbar: TheNavbar
        },

        meta: {
            requiresAuth: true,
        },

        children: [
            {
                path: 'sensor/:id',
                name: 'Sensor Data',
                component:  SensorDialog,
                props: true,
            }
        ]
    },

    {
        path: "/forgot-password",
        name: "ForgotPassword",
        component: ForgotPassword,
    },

    {
        path: "/password-reset",
        name: "ResetPassword",
        component: ResetPassword
    },

    {
        path: "/verify",
        name: "Verify",
        component: Verify
    },

    /**Not Found Page catching all URLS after every other check fails */

    {
        path: "/not-found",
        name: "NotFound",
        components: {
            default:    NotFound,
        },
        alias: "/*"
    },
];

export default routes;