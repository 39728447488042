export const CONFIG = {
    main_api:                   process.env.VUE_APP_API_URL,
    locale:                     process.env.VUE_APP_I18N_LOCALE,
    fallback_locale:            process.env.VUE_APP_I18N_FALLBACK_LOCALE,

    annex_machine_list: [
        {id: '1', name: "Extrusion", state: true, "x":4.8,"y":4.5,"w":2,"h":9.5,"i":"0", static: false},
        {id: '2', name: "Extrusion", state: true, "x":7,"y":4.5,"w":2,"h": 9.5,"i":"1", static: false},
        {id: '3', name: "Extrusion", state: true,"x":9.2,"y":4.5,"w":4,"h":9.5,"i":"2", static: false},
        {id: '4', name: "Extrusion", state: true, "x":13.6,"y":4.5,"w":2,"h": 9.5,"i":"3", static: false},
        {id: '5', name: "Extrusion", state: true,"x":15.8,"y":4.5,"w":2,"h":9.5,"i":"4", static: false},
        {id: '6', name: "Extrusion", state: true, "x":17.8,"y":4.5,"w":2,"h":9.5,"i":"5", static: false},
        {id: '7', name: "Extrusion", state: true, "x":20,"y":4.5,"w":2,"h": 9.5,"i":"6", static: false},
        {id: '8', name: "Extrusion", state: true,"x":22.1,"y":4.5,"w":2,"h":9.5,"i":"7", static: false},
        {id: '9', name: "Extrusion", state: true, "x":24.2,"y":4.5,"w":2,"h":9.5,"i":"8", static: false},
        {id: '10', name: "Extrusion", state: true, "x":26.3,"y":4.5,"w":2,"h": 9.5,"i":"9", static: false},
        {id: '11', name: "Extrusion", state: true,"x":28.3,"y":4.5,"w":2,"h":9.5,"i":"10", static: false},
        {id: '12', name: "Extrusion", state: true,"x":30.4,"y":4.5,"w":2,"h":9.5,"i":"11", static: false},
        {id: '13', name: "Maquinas granuladora de PVC", state: true,"x":37,"y":3.5,"w":3.5,"h":10,"i":"12", static: false},
    ],

    annex_sensor_list: [
        {id: 1, name: "A1", "x":44,"y":16.5,"w":1,"h":1,"i":"0", static: false, enviIndex: 3, thermalComfort: 4},
        {id: 2, name: "A2", "x":34,"y": 8,"w":1,"h":1,"i":"1", static: false, enviIndex: 4, thermalComfort: 3},
        {id: 3, name: "A3", "x":28,"y": 8,"w":1,"h":1,"i":"2", static: false, enviIndex: 4, thermalComfort: 3},
        {id: 4, name: "A4", "x":2.5,"y": 3,"w":1,"h":1,"i":"3", static: false, enviIndex: 3, thermalComfort: 4},
        {id: 5, name: "A5", "x":13,"y": 13,"w":1,"h":1,"i":"4", static: false, enviIndex: 4, thermalComfort: 3},
    ],

    fagesta_sensor_list: [
        {id: '1', name: "A1", "x":21,"y":0,"w":1,"h":1,"i":"0", static: false},
        {id: '2', name: "A2", "x":33,"y": 3,"w":1,"h":1,"i":"1", static: false},
        {id: '3', name: "B1", "x":48,"y": 11.5,"w":1,"h":1,"i":"2", static: false},
        {id: '4', name: "B2", "x":16,"y": 16.5,"w":1,"h":1,"i":"3", static: false},
        
    ],

    fagesta_machine_list: [
        {id: '1', name: "Soldadura", state: true, "x":44,"y":8.3,"w":5,"h":8.5,"i":"0", static: false},
        {id: '2', name: "Torno paralelo", state: true,"x":12.7,"y":0.6,"w":8,"h":2.5,"i":"1", static: false},
        {id: '3', name: "Torno paralelo", state: true,"x":12.7,"y":5.6,"w":8,"h":2.5,"i":"2", static: false},
        {id: '4', name: "Fresadora universal", state: true,"x":12,"y":14,"w":3.5,"h":4,"i":"3", static: false},
        {id: '5', name: "Torno automatico", state: true,"x":23,"y":0.7,"w":9,"h":2.5,"i":"4", static: false},
        {id: '6', name: "Torno paralelo", state: true,"x":23,"y":5,"w":9,"h":2.5,"i":"5", static: false},
        {id: '7', name: "Torno automatico", state: true,"x":32.5,"y":6.5,"w":2.5,"h":6,"i":"6", static: false},
    ],  
};

export default CONFIG;
