const AQColors = [
    {value: 1, color: 'aqgreen'},
    {value: 2, color: 'aqblue'},
    {value: 3, color: 'aqyellow'},
    {value: 4, color: 'aqred'}
];

const AQTexts = [
    {value: 1, text: 'verygood'},
    {value: 2, text: 'good'},
    {value: 3, text: 'medium'},
    {value: 4, text: 'bad'}
];


export const getAQText = (value) => {
    const AQItem = AQTexts.find(item => item.value === value);
    if(!AQItem) return 'undefined'
    return AQItem.text;
}


export const getAQColor = (value) => {
    const AQItem = AQColors.find(item => item.value === value);
    if(!AQItem) return 'grey'
    return AQItem.color;
}

export const getAQColorByText = (text) => {
    const AQItem = AQTexts.find(item => item.text === text);
    if(!AQItem) return 'grey'

    return getAQColor(AQItem.value)
}