<template>
    <div class="d-flex flex-column justify-center align-center">
        <div class="image__container">
            <div class="icon__container">
                <v-icon :color="stateColor" class="state_icon">
                    {{ stateIcon }}
                </v-icon>
            </div>
            <img
                :src="require('../../assets/legend/machines.png')"
                width="35"
                height="35"
                class="pa-0 ma-0"
            >
        </div>
        <p
            class="text-center pa-0 ma-0 font-weight-bold text-caption"
            :class="{ name_vertical: name === 'Extrusion' }"
        >
            {{ name }}
        </p>
    </div>
</template>

<script>
export default {
    name: "MachineIcon",

    props: {
        state: {
            type: Boolean,
            required: false,
            default: true,
        },

        name: {
            type: String,
            required: true,
        },
    },

    computed: {
        stateIcon() {
            if (!this.state) return "fas fa-times-circle";
            return "fas fa-check-circle";
        },

        stateColor() {
            if (!this.state) return "red";
            return "green";
        },
    },
};
</script>

<style scoped>
.state_icon {
    position: absolute;
    right: -5px;
    top: -8px;
}

.image__container {
    position: relative;
}

.name_vertical {
    transform: rotateZ(-90deg) translateX(-15px);
}

i::before {
    box-sizing: border-box;
    background: white;
    border-radius: 50%;
}
</style>
