require("./overrides.scss");
export default {
  theme: {
    themes: {
      light: {
        primary:            "#0680C3",
        secondary:          "#F0F9FE",
        accent:             "#9fb1bc",
        error:              "#a84451",
        info:               "#4a62ab",
        success:            "#31918e",
        warning:            "#fb7b6b",
        blueish:            "#eaeeff",
        white:              "#fff",
        black:              "#202020",
        linkHover:          "#e4e4e4",
        anchor:             "#fff",
        aqgreen:            "#2C7649",
        aqblue:             "#2477BF",
        aqyellow:           "#F2BB12",
        aqred:              "#A6000A",
        modalText:          "#00474F"
      },
    },
  },
};
