<template>
    <grid-layout
                :layout.sync="machineList"
                :col-num="50"
                :row-height="34"
                :max-rows="20"
                :is-draggable="false"
                :is-resizable="false"
                :is-mirrored="false"
                :vertical-compact="false"
                :margin="[0, 0]"
                :use-css-transforms="false"
            >
                <grid-item 
                    v-for="item in machineList"
                    :key="item.id"
                    :static="item.static"
                    :x="item.x"
                    :y="item.y"
                    :w="item.w"
                    :h="item.h"
                    :i="item.i"
                >
                    <div
                        class="grid_item__container"
                    >
                        <machine-icon 
                            :state="item.state" 
                            :name="item.name"
                        ></machine-icon>
                    </div>
                </grid-item>
            </grid-layout>
</template>

<script>
import MachineIcon from './MachineIcon.vue';
import VueGridLayout from 'vue-grid-layout' ;

export default {
    name: "MachineGrid",

    props: {
        machineList: {
            type: Array,
            required: true,
        },
    },

    components: {
        GridLayout: VueGridLayout.GridLayout,
        GridItem: VueGridLayout.GridItem,
        MachineIcon,
    },

    
}
</script>

<style scoped>
.vue-grid-layout{
    padding: 0 10% !important;
}

.vue-grid-item:not(.vue-grid-placeholder) {
    background: #F0F9FE;
    border: 5px solid black;
}


.grid_item__container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

</style>