<template>
    <v-row 
        align="center"
        justify="center"
        class="not_found__container"
    >
        <v-col
            cols="12"
            class="text-center"
        >
        <p>{{ $t('NotFound.title') }}</p>
        <button @click="returnHome()">
            {{ $t('NotFound.backBtn') }}
        </button>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: "NotFoundPage",

    methods: {
        returnHome(){
            this.$router.replace("/");
        }
    }
}
</script>

<style scoped>
.not_found__container{
    height: 80vh;
}
</style>