

const setMachineList = (state, payload) => {
    state.machineList = [...payload]
}

const setTime = (state, payload) => {
    state.time = {...payload}
}

const setPeopleOnShift = (state, payload) => {
    state.peopleOnShift = payload
}

const setVentilation = (state, payload) => {
    state.ventilation = payload
}

const setSensorsList = (state, payload) => {
    state.sensorsList = [...payload]
}

const setSimData = (state, payload) => {
    state.sensorsList = state.sensorsList.map(item => {
        const itemToChange = payload.find(payloadItem => item.id === payloadItem.id )
        const newItem = {...item, enviIndex: itemToChange.enviIndex, thermalComfort: itemToChange.thermalComfort}
        return newItem;

    })
}

const clearData = (state) => {
    state.sensorsList = [];
    state.machineList = [];
}

  export default{
      setMachineList,
      setTime,
      setPeopleOnShift,
      setVentilation,
      setSimData,
      setSensorsList,
      clearData,
  }