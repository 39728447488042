<template>
    <v-row>
        <v-col
            v-show="$vuetify.breakpoint.smAndUp"
            cols="12"
            md="3"
            :order="$vuetify.breakpoint.mdAndUp ? '0' : '12'"
            class="side_menu px-4"
        >
            <v-row>
                <v-col>
                    <p
                        class="text-center text-body-1 text-md-h6 primary--text font-weight-bold mb-0"
                    >
                       {{$t("Home.infoTitle")}}
                    </p>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="px-8 py-0 side_menu__info">
                    <p class="ma-0">
                        {{ $t("Home.info") }}
                        <a href="mailto:  info@draxis.gr" class="primary--text">
                            info@draxis.gr
                        </a>
                    </p>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="logo_container text-center">
                    <img
                        :src="
                            require(`../assets/factories/factory_${userRole}.png`)
                        "
                        class="factory_logo"
                    >
                </v-col>
            </v-row>
        </v-col>
        <v-col class="main__content" order="1">
            <div class="image pt-10 mb-10">
                <img
                    :src="userRole === '2' ? fagesta : lisanplast"
                    width="100%"
                    height="600"
                >
            </div>
            <div class="image__layer pt-10 mb-10">
                <machine-grid :machine-list="machineList"></machine-grid>
            </div>
            <div>
                <div
                    v-if="getSensorsStatus_Success"
                    class="image__layer pt-10 mb-10"
                >
                    <sensor-grid
                        :sensor-list="sensorsData"
                        :data-layer="sensorMode"
                        :class="{ flashing: animationActive }"
                    ></sensor-grid>
                </div>
                <the-legend v-if="$vuetify.breakpoint.mdAndUp"></the-legend>
                <dropdown :data="sensorMode"></dropdown>
            </div>
        </v-col>
    </v-row>
</template>

<script>
import TheLegend from "../components/TheLegend.vue";
import Dropdown from "./home/Dropdown.vue";
import Toggle from "./home/Toggle.vue";

import SensorGrid from "./home/SensorGrid.vue";
import MachineGrid from "./home/MachineGrid.vue";

import { mapState } from "vuex";

import { withAsync } from "../helpers/withAsync";
import { getSensors } from "../api/sensorsApi.js";
import { apiStatus } from "../api/constants/apiStatus.js";
import { apiStatusComputed } from "../api/helpers/computedApiStatus.js";

export default {
    name: "Home",

    components: {
        TheLegend,
        Dropdown,
        Toggle,
        SensorGrid,
        MachineGrid,
    },

    data() {
        return {
            getSensorsStatus: apiStatus.Idle,

            lisanplast: require("../assets/factories/lisanplastbnw.png"),
            fagesta: require("../assets/factories/fagestabnw.jpg"),

            animationActive: false,
        };
    },

    computed: {
        ...mapState({
            machineList: (state) => state.sim.machineList,
            sensorsData: (state) => state.sensors.sensorsData,
            sensorMode: (state) => state.sensors.mode,
            userRole: (state) => state.auth.userRole,
        }),
        ...apiStatusComputed("getSensorsStatus"),
    },

    methods: {
        async getSensorsData() {
            this.getSensorsStatus = apiStatus.Pending;

            const { response, error } = await withAsync(getSensors);

            if (error) {
                console.log(error.response);
                this.getSensorsStatus = apiStatus.Error;
                return;
            }
            this.getSensorsStatus = apiStatus.Success;

            const sensors = response.data.data.sensors;
            const machines = response.data.data.machines;

            this.$store.dispatch("sensors/setSensorsData", sensors);
            this.$store.dispatch("sim/setMachineList", machines);
        },

        init() {
            this.$connect();
            this.$socket.onopen = () => {
                this.$socket.send(this.userRole);
            };
            this.$options.sockets.onmessage = (data) => {
                const refinedData = JSON.parse(data.data);
                this.$store.dispatch("sensors/changeData", refinedData);
            };
        },
    },

    watch: {
        sensorMode() {
            this.animationActive = true;
            setTimeout(() => {
                this.animationActive = false;
            }, 300);
        },
    },

    mounted() {
        this.getSensorsData();
        this.init();
    },
};
</script>

<style scoped>
.side_menu {
    padding: 30px;
    width: 100%;
    height: calc(100vh - 80px);
    position: relative;
}

.side_menu__info {
    margin-bottom: 150px;
    height: calc(95vh - 300px);
    overflow: auto;
}

.image {
    position: relative;
    top: 50px;
    opacity: 0.5;
    width: 100%;
    padding: 0 5%;
}

.image__layer {
    height: 600px;
    width: 100%;
    position: absolute;
    top: 50px;
    padding: 0 5%;
}

.main__content {
    background-color: white;
    position: relative;
    border-left: 1px solid black;
}

.flashing {
    animation: flash 0.3s;
}
.logo_container {
    position: absolute;
    bottom: 5px;
}
.factory_logo {
    max-width: 100%;
}

@keyframes flash {
    0% {
        opacity: 1;
    }
    20% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}

@media only screen and (max-height: 620px) {
    .side_menu__info {
        margin-bottom: 20px;
        height: 100%;
    }
    .logo_container {
        position: static;
    }
}

@media only screen and (max-width: 960px) {
    .side_menu {
        height: 100%;
    }
    .side_menu__info {
        height: 100%;
        margin-bottom: 20px;
    }

    .logo_container {
        position: static;
    }
}
</style>
