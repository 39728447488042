<template>
    <v-row>
        <v-col>
            <grid-layout
            :layout.sync="sensorList"
            :col-num="50"
            :row-height="34"
            :max-rows="20"
            :is-draggable="false"
            :is-resizable="false"
            :is-mirrored="false"
            :vertical-compact="false"
            :margin="[0, 0]"
            :use-css-transforms="false"
        >
            <grid-item
                v-for="item in sensorList"
                :key="item.id"
                :static="item.static"
                :x="item.x"
                :y="item.y"
                :w="item.w"
                :h="item.h"
                :i="item.i"
            >
                <div 
                    class="grid_item__container" 
                    @click="handleSensorClick(item)"
                >
                    <sensor-icon :index="indexShown(item)"></sensor-icon>
                </div>
            </grid-item>
            <router-view></router-view>
        </grid-layout>
        </v-col>
    </v-row>
</template>

<script>
import VueGridLayout from "vue-grid-layout";
import SensorIcon from "./SensorIcon.vue";

export default {
    name: "SensorGrid",

    props: {
        sensorList: {
            type: Array,
            required: true,
        },

        dataLayer: {
            type: String,
            required: true,
        },
    },
    components: {
        GridLayout: VueGridLayout.GridLayout,
        GridItem: VueGridLayout.GridItem,
        SensorIcon,
    },

    data() {
        return {
            
        }
    },

    methods: {
        handleSensorClick(item) {
            const path = this.$route.path === '/' ? '' : '/simulation'
            this.$router.push({
                path: `${path}/sensor/${item.id}`,
                query: { mode: `${this.dataLayer}` },
            });
        },

        indexShown(item) {
            if (this.dataLayer === "aqi") return item.enviIndex;
            if (this.dataLayer === "tci") return item.thermalComfort;
            return 0;
        },

        closeDialog() {
            this.dialogShown = false;
        },
    },
};
</script>

<style scoped>
.vue-grid-layout{
    height: 600px !important;
}
.vue-grid-item:not(.vue-grid-placeholder) {
    background: transparent;
}

.grid_item__container {
    display: flex;
    justify-content: center;
    height: 100%;
}

.grid_item__container:hover {
    cursor: pointer;
}
</style>
