import { api } from "./api";
import store from "../store"

const URLS = {
    sensors:      "sensors",
    sensorMeasurements: "sensors",
    lastMeasurement: "sensors",
    recommendations: "sensors"
}

export const getSensors = () => {
    const role = store.state.auth.userRole;
    return api.get(`${URLS.sensors}?factory=${role}`);
};

export const getSpecificSensor = (payload) => {
    return api.get(`${URLS.sensorMeasurements}/${payload.id}/plot_measurements?pollutant=${payload.name}`);
};

export const getLastMeasurements = (payload) => {
    return api.get(`${URLS.lastMeasurement}/${payload}/last_measurement`);
};

export const getGroupRecommendations = (payload) => {
    return api.get(`${URLS.recommendations}/${payload}/groups`);
};