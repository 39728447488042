<template>
    <v-dialog
        v-model="showDialog"
        width="600"
        scrollable
        @click:outside="closeDialog()"
    >
        <v-card height="750">
            <!-- Title Section -->
            <v-card-title class="pa-0 ma-0 dialog_title">
                <v-row class="pa-0 pt-3 ma-0">
                    <v-col cols="10" class="pa-0">
                        <v-tabs
                            v-model="selectedTab"
                            background-color="primary"
                            class="pa-0 ma-0"
                            dark
                        >
                            <v-tab>
                                {{ $t("SensorDialog.Tabs.overview") }}
                            </v-tab>
                            <v-tab v-show="sensorMode === 'aqi'">
                                {{ $t("SensorDialog.Tabs.recoms") }}
                            </v-tab>
                        </v-tabs>
                    </v-col>
                    <v-col class="pa-0 pr-3 ma-0 text-right">
                        <v-btn icon @click="closeDialog()">
                            <v-icon color="secondary"> fas fa-times </v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <!-- End of Title Section -->

            <!-- Overview Tab Section -->
            <v-card-text v-if="selectedTab === 0" class="pa-5">
                <v-row>
                    <v-col class="text-h6 modalText--text font-weight-bold">
                        {{ $t(`SensorDialog.MainContent.${sensorMode}`) }}
                        Sensor {{ selectedSensor }}
                    </v-col>
                </v-row>
                <v-row align="center" class="pa-0 ma-0">
                    <v-col
                        cols="5"
                        md="3"
                        class="text-body-2 text--secondary pa-0 ma-0"
                    >
                        {{ $t("SensorDialog.MainContent.realTime") }}
                    </v-col>
                    <v-col class="pa-2">
                        <span :class="[realTimeAQClasses]">
                            {{ $t(`${sensorMode}.${realTimeAQ}`) }}
                        </span>
                    </v-col>
                </v-row>
                <v-row align="center" class="pa-0 ma-0">
                    <v-col
                        cols="5"
                        md="3"
                        class="text-body-2 text--secondary pa-0 ma-0"
                    >
                        {{ $t("SensorDialog.MainContent.simulated") }}
                    </v-col>
                    <v-col class="pa-2">
                        <span :class="[simulatedAQClasses]">
                            {{ $t(`${sensorMode}.${simulatedAQ}`) }}
                        </span>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="pt-5">
                    <v-col class="text-h6 modalText--text font-weight-bold">
                        {{ $t("SensorDialog.MainContent.realTimeOverview") }}
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="pa-0">
                        <v-slide-group
                            v-model="selectedPollutantIndex"
                            show-arrows
                            mandatory
                        >
                            <v-slide-item
                                v-for="item in pollutants"
                                v-slot="{ active, toggle }"
                                :key="item.name"
                            >
                                <v-card
                                    class="ma-4"
                                    height="60"
                                    :width="isAirQuality ? '100' : '150'"
                                    :color="active ? 'modalText' : 'secondary'"
                                    rounded="lg"
                                    @click="toggle"
                                >
                                    <v-card-text
                                        class="pa-2"
                                        :class="
                                            active
                                                ? 'secondary--text'
                                                : 'modalText--text'
                                        "
                                    >
                                        <p
                                            class="font-weight-bold text-body-2 text-center pa-0 ma-0"
                                        >
                                            {{ convertPollutant(item.name) }}
                                            <sub>
                                                {{ getNumber(item.name) }}
                                            </sub>
                                        </p>
                                        <p
                                            v-if="isAirQuality"
                                            class="font-weight-bold text-caption text-center"
                                        >
                                            {{ item.value }} μg/m<sup>3</sup>
                                        </p>
                                        <p
                                            v-else
                                            class="font-weight-bold text-caption text-center"
                                        >
                                            {{ item.value }}
                                            {{ getMeasurementUnit(item.name) }}
                                        </p>
                                    </v-card-text>
                                </v-card>
                            </v-slide-item>
                        </v-slide-group>
                    </v-col>
                </v-row>
                <v-row v-if="getSensorStatus_Success">
                    <v-col>
                        <sensor-chart
                            :pollutant="selectedPollutant"
                            :values="pollutantValues"
                            :dates="pollutantDates"
                        ></sensor-chart>
                    </v-col>
                </v-row>
                <v-row v-if="getSensorStatus_Success">
                    <v-col class="text-right text-caption">
                        Last update {{ measurementDate }}
                    </v-col>
                </v-row>
            </v-card-text>
            <!-- End of Overview Tab Section -->

            <!-- Recommendations Tab Section -->
            <v-card-text v-else class="pa-5">
                <v-row>
                    <v-col class="text-h5 modalText--text font-weight-bold">
                        {{ $t("SensorDialog.MainContent.generalRecoms") }}
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="pa-0">
                        <v-list>
                            <template v-for="(group, index) in recommendations">
                                <v-list-item
                                    :key="index"
                                    class="px-5 ma-0 pa-4"
                                >
                                    <v-row class="ma-0 pa-0">
                                        <v-col class="pa-0">
                                            <v-row>
                                                <v-col class="text-center pa-0">
                                                    <p
                                                        class="ma-0 font-weight-bold modalText--text"
                                                    >
                                                        {{
                                                            $t(
                                                                `Recommendations.groups.${group.group}`
                                                            )
                                                        }}
                                                    </p>
                                                </v-col>
                                            </v-row>
                                            <v-row align="end">
                                                <v-col cols="3" class="pa-0">
                                                    <v-row
                                                        align="center"
                                                        justify="center"
                                                    >
                                                        <v-col>
                                                            <v-img
                                                                :src="
                                                                    require(`../../assets/groups/${group.name}.svg`)
                                                                "
                                                                height="50"
                                                                width="50"
                                                            ></v-img>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                                <v-col
                                                    cols="8"
                                                    class="text-body-2 text-left modalText--text"
                                                    style="
                                                        word-wrap: break-word;
                                                    "
                                                >
                                                    {{
                                                        $t(
                                                            `Recommendations.recommendations.${group.recommendation.replace(
                                                                /[ ,\.]/g,
                                                                ""
                                                            )}`
                                                        )
                                                    }}
                                                </v-col>
                                                <v-col class="text-center">
                                                    <v-icon
                                                        :color="
                                                            getAQColorByScale(
                                                                group.scale
                                                            )
                                                        "
                                                        size="20"
                                                    >
                                                        fas fa-circle
                                                    </v-icon>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-list-item>
                                <v-divider
                                    v-show="group.id !== recommendations.length"
                                    :key="group.groupName"
                                ></v-divider>
                            </template>
                        </v-list>
                    </v-col>
                </v-row>
            </v-card-text>
            <!-- End of Recommendations Tab Section -->
        </v-card>
    </v-dialog>
</template>

<script>
import { apiStatus } from "../../api/constants/apiStatus.js";
import {
    getAQColor,
    getAQText,
    getAQColorByText,
} from "../../helpers/AQindexes.js";
import { withAsync } from "../../helpers/withAsync";
import {
    getSpecificSensor,
    getLastMeasurements,
    getGroupRecommendations,
} from "../../api/sensorsApi.js";
import { apiStatusComputed } from "../../api/helpers/computedApiStatus.js";

import SensorChart from "./SensorChart.vue";

import { mapState } from "vuex";

export default {
    name: "SensorDialog",

    props: {
        id: {
            type: String,
            required: true,
        },
    },

    components: {
        SensorChart,
    },

    data() {
        return {
            showDialog: true,
            getSensorStatus: apiStatus.Idle,
            getRecommendationsStatus: apiStatus.Idle,

            selectedPollutant: "PM2.5",
            selectedPollutantIndex: null,

            realTimeIndex: 0,
            simulatedIndex: 0,

            selectedTab: 0,
            selectedSensor: this.id,

            recommendations: [],

            pollutants: [],
            pollutantValues: null,
            pollutantDates: null,
            measurementDate: "",
        };
    },

    computed: {
        realTimeAQ() {
            return getAQText(this.realTimeIndex);
        },

        simulatedAQ() {
            return getAQText(this.simulatedIndex);
        },

        realTimeAQClasses() {
            const color = getAQColor(this.realTimeIndex);
            return `${color}--text font-weight-bold text-h6 font-italic`;
        },

        simulatedAQClasses() {
            const color = getAQColor(this.simulatedIndex);
            return `${color}--text font-weight-bold text-h6 font-italic`;
        },

        ...mapState({
            simulatedData: (state) => state.sim.sensorsList,
            liveData: (state) => state.sensors.sensorsData,
            sensorMode: (state) => state.sensors.mode,
        }),

        ...apiStatusComputed("getSensorStatus", "getRecommendationsStatus"),

        isAirQuality() {
            return this.sensorMode === "aqi";
        },
    },

    methods: {
        closeDialog() {
            this.showDialog = false;
        },

        convertPollutant(pollutant) {
            if (pollutant === "humidity" || pollutant === "temperature") {
                return this.$t(`SensorDialog.MainContent.${pollutant}`);
            }
            let letr = pollutant.match(/[a-zA-Z]+/g);

            const refinedWord = letr[0].toUpperCase();
            return refinedWord;
        },

        getNumber(pollutant) {
            let num = pollutant.match(/\d+/g);
            if (!num) return "";
            return num[0];
        },

        getMeasurementUnit(name) {
            if (name === "humidity") return "%";
            else return "°C";
        },

        aqColor(index) {
            return getAQColor(index);
        },

        getAQColorByScale(text) {
            return getAQColorByText(text);
        },

        handlePollutantClick(item) {
            this.selectedPollutant = item;
            this.getSensorData(item);
        },

        async getSensorData(pollutant) {
            this.getSensorStatus = apiStatus.Pending;
            const payload = {
                id: this.id,
                name: pollutant,
            };

            const { response, error } = await withAsync(
                getSpecificSensor,
                payload
            );

            if (error) {
                this.getSensorStatus = apiStatus.Error;
                return;
            }

            const data = response.data.data[payload.name];
            this.pollutantValues = data.values;
            this.pollutantDates = data.dates;
            this.getSensorStatus = apiStatus.Success;
        },
        async getLastSensorMeasurements() {
            const payload = this.id;

            const { response, error } = await withAsync(
                getLastMeasurements,
                payload
            );

            if (error) {
                return;
            }

            this.measurementDate = response.data.data.datetime;
            let data;

            if (this.sensorMode === "aqi") {
                data = response.data.data.pollutants;
            } else {
                data = {
                    temperature: response.data.data.temperature,
                    humidity: response.data.data.humidity,
                };
            }

            for (let item in data) {
                if (!data[item]) continue;
                this.pollutants.push({ name: item, value: data[item] });
            }

            let [firstPollutant] = Object.keys(data);

            this.selectedPollutant = firstPollutant;
            this.getSensorData(firstPollutant); //Initialize chart with the data of the first pollutant
        },

        async getRecommendations() {
            const payload = this.id;
            this.getRecommendationsStatus = apiStatus.Pending;

            const { response, error } = await withAsync(
                getGroupRecommendations,
                payload
            );

            if (error) {
                this.getRecommendationsStatus = apiStatus.Error;
                return;
            }

            const groups = response.data.data.groups;

            const newGroups = groups.map((item) => {
                const newName = item.name.replace(/\s+/g, "");
                const newScale = item.scale.replace(/\s+/g, "").toLowerCase();
                return {
                    ...item,
                    group: item.name,
                    name: newName,
                    scale: newScale,
                };
            });
            this.recommendations = [...newGroups];
            this.getRecommendationsStatus = apiStatus.Success;
        },

        setAQIndexes() {
            const realTimeSensor = this.liveData.find(
                (item) => item.id === parseInt(this.id)
            );
            this.realTimeIndex =
                this.sensorMode === "aqi"
                    ? realTimeSensor.enviIndex
                    : realTimeSensor.thermalComfort;

            if (!this.simulatedData || this.simulatedData.length === 0) return; //Showing undefined if user hasn't run simulation yet
            const simulatedSensor = this.simulatedData.find(
                (item) => item.id === parseInt(this.id)
            );

            this.simulatedIndex =
                this.sensorMode === "aqi"
                    ? simulatedSensor.enviIndex
                    : simulatedSensor.thermalComfort;
        },
    },

    watch: {
        showDialog(newVal) {
            if (!newVal) this.$router.go(-1);
        },
        selectedPollutantIndex(newVal) {
            const pollutant = this.pollutants[newVal];
            this.handlePollutantClick(pollutant.name);
        },
    },

    created() {
        this.getLastSensorMeasurements();
        this.getRecommendations();
        this.setAQIndexes();
    },
};
</script>

<style scoped>
.dialog_title {
    background-color: #0680c3;
}
</style>
