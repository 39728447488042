<template>
    <v-row>
        <v-col
        cols="12"
        md="3"
        class=" pt-3 px-4 side_menu"
        :order="$vuetify.breakpoint.mdAndUp ? '0' : '12'"
        >
            <v-row>
                <v-col>
                    <p
                        class="text-center text-body-1 text-md-h6 primary--text font-weight-bold mb-0"
                    >
                        {{ $t("Home.SideMenu.machines") }}
                    </p>
                </v-col>
            </v-row>

            <!-- Machine Select -->
            <v-row>
                <v-col>
                    <machine-toggle :machines="machineList"></machine-toggle>
                </v-col>
            </v-row>
            <!-- End of Machine Select -->
            <v-row>
                <!-- Ventilation -->
                <v-col>
                    <v-row>
                        <v-col>
                            <p
                                class="text-center text-body-1 text-md-h6 primary--text font-weight-bold mb-0"
                            >
                                {{ $t("Home.SideMenu.vent") }}
                            </p>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <ventilation></ventilation>
                        </v-col>
                    </v-row>
                </v-col>
                <!--End of Ventilation -->
            </v-row>

            <v-row justify="center">
                <!-- People on Shift -->
                <v-col
                cols="4"
                lg="4"
                >
                    <v-card height="80">
                        <v-card-text class="d-flex flex-column align-center">
                            <v-icon size="25">
                            fas fa-user
                            </v-icon>
                            <v-text-field
                                :value="peopleOnShift"
                                type="number"
                                min="0"
                                class="ma-0 pa-0 text-right"
                                @input="handlePeopleOnShiftInput($event)"
                            >
                            </v-text-field>
                        </v-card-text>
                    </v-card>
                </v-col>
                <!-- End of People on Shift -->
                <!-- Time Picker -->
                <v-col>
                    <v-card
                    height="80"
                    @click="showTimeDialog = true"
                    >
                        <v-card-text class="side_clock">
                            <v-icon class="pr-4">
                            fas fa-clock
                            </v-icon>
                            <div class="side_clock_time">
                                <p class="ma-0">
                                    {{ $t("Home.SideMenu.from") }}
                                    {{ time.from }}
                                </p>
                                <p class="ma-0 pl-4">
                                    {{ $t("Home.SideMenu.to") }} {{ time.to }}
                                </p>
                            </div>
                        </v-card-text>
                    </v-card>

                    <time-picker
                        :show.sync="showTimeDialog"
                        :time="{ ...time }"
                    ></time-picker>
                </v-col>

                <!-- End of time picker -->
            </v-row>

            <!-- Calculate Btn -->
            <v-row>
                <v-col class="text-center mt-5 pb-10 mb-10">
                    <p class="text-body-2 text--secondary">
                        {{ $t("Home.SideMenu.calculateDescription") }}
                    </p>
                    <v-btn
                        color="primary"
                        large
                        depressed
                        :loading="getCalculationStatus_Pending"
                        @click="runSimulation()"
                    >
                        {{ $t("Home.SideMenu.calculateBtn") }}
                    </v-btn>
                </v-col>
            </v-row>
            <!-- End of Calculate Btn -->

            <!-- Show Guide -->
            <v-row>
                <v-col class="text-center show_guide_button">
                    <v-btn
                        text
                        color="modalText"
                        class="text--secondary"
                        @click="showGuide = true"
                    >
                        {{ $t("Home.SideMenu.showGuide") }}
                    </v-btn>
                    <v-dialog
                    v-model="showGuide"
                    width="700"
                    >
                        <guide :show.sync="showGuide"></guide>
                    </v-dialog>
                </v-col>
            </v-row>
            <!-- End of Show Guide -->
        </v-col>
        <!-- Factory Map -->
        <v-col class="main__content">
            <div class="image pt-10 mb-10">
                <img
                    :src="userRole === '2' ? fagesta : lisanplast"
                    width="100%"
                    height="600"
                >
            </div>
            <div v-if="getSensorsStatus_Success">
                <div class="image__layer pt-10 mb-10">
                    <machine-grid :machine-list="machineList"></machine-grid>
                </div>
                <div class="image__layer pt-10 mb-10">
                    <sensor-grid
                        :sensor-list="sensorsList"
                        :data-layer="sensorMode"
                        :class="{ flashing: animationActive }"
                    ></sensor-grid>
                </div>
                <the-legend v-if="$vuetify.breakpoint.mdAndUp"></the-legend>
                <dropdown :data="sensorMode"></dropdown>
            </div>
        </v-col>
        <!-- End of Factory Map -->
    </v-row>
</template>

<script>
import TheLegend from "../components/TheLegend.vue";
import Dropdown from "./home/Dropdown.vue";

import TimePicker from "./simulation/TimePicker.vue";
import SensorGrid from "./home/SensorGrid.vue";
import MachineGrid from "./home/MachineGrid.vue";
import MachineToggle from "./simulation/MachineToggle.vue";
import Ventilation from "./simulation/Ventilation.vue";
import Guide from "./simulation/Guide.vue";

import { mapState } from "vuex";
import { withAsync } from "../helpers/withAsync";
import { getSensors } from "../api/sensorsApi.js";
import { calculateData } from "../api/calculationApi.js";
import { apiStatus } from "../api/constants/apiStatus.js";
import { apiStatusComputed } from "../api/helpers/computedApiStatus.js";

export default {
    name: "Home",

    components: {
        TheLegend,
        Dropdown,
        SensorGrid,
        MachineGrid,
        TimePicker,
        MachineToggle,
        Ventilation,
        Guide,
    },

    data() {
        return {
            getSensorsStatus: apiStatus.Idle,
            getCalculationStatus: apiStatus.Idle,

            showTimeDialog: false,

            lisanplast: require("../assets/factories/lisanplastbnw.png"),
            fagesta: require("../assets/factories/fagestabnw.jpg"),

            animationActive: false,
            showGuide: false,
        };
    },

    computed: {
        ...mapState({
            userRole: (state) => state.auth.userRole,
            sensorsList: (state) => state.sim.sensorsList,
            sensorMode: (state) => state.sensors.mode,
            machineList: (state) => state.sim.machineList,
            time: (state) => state.sim.time,
            peopleOnShift: (state) => state.sim.peopleOnShift,
            ventilation: (state) => state.sim.ventilation,
            factory: (state) => state.auth.userRole,
        }),
        ...apiStatusComputed(["getSensorsStatus", "getCalculationStatus"]),
    },

    methods: {
        async getSensorsData() {
            this.getSensorsStatus = apiStatus.Pending;

            const { response, error } = await withAsync(getSensors);

            if (error) {
                console.log(error.response);
                this.getSensorsStatus = apiStatus.Error;
                return;
            }
            this.getSensorsStatus = apiStatus.Success;

            const sensors = response.data.data.sensors;
            const machines = response.data.data.machines;

            this.$store.dispatch("sensors/setSensorsData", sensors);
            this.$store.dispatch("sim/setMachineList", machines);

            let immutableData = [];

            immutableData = sensors.map((item) => {
                return { ...item };
            });

            if (this.sensorsList.length > 0) return; //if there are already sensors registered, don't set them
            this.$store.dispatch("sim/setSensorsList", immutableData);
        },

        handlePeopleOnShiftInput(event) {
            this.$store.dispatch("sim/setPeopleOnShift", event);
        },

        convertTime(time) {
            const timeArray = time.split(":");
            const timeSeconds = +timeArray[0] * 3600 + +timeArray[1] * 60;
            return timeSeconds;
        },

        getSecondsDifference(from, to) {
            const fromSeconds = this.convertTime(from);
            const toSeconds = this.convertTime(to);

            const absDiff = Math.abs(fromSeconds - toSeconds);

            if (toSeconds <= fromSeconds) {
                return 86400 - absDiff;
            }

            return absDiff;
        },

        async runSimulation() {
            const workingMachines = this.machineList
                .filter((item) => item.state === true)
                .map((item) => item.id);
            const secondDifference = this.getSecondsDifference(
                this.time.from,
                this.time.to
            );

            const payload = {
                machines: [...workingMachines],
                people: +this.peopleOnShift,
                time: secondDifference,
                ventilation: this.ventilation,
                factory: +this.factory,
            };

            this.getCalculationStatus = apiStatus.Pending;

            const { response, error } = await withAsync(calculateData, payload);

            if (error) {
                console.log(error.response);
                this.getCalculationStatus = apiStatus.Error;
                return;
            }

            const data = response.data.data;
            this.getCalculationStatus = apiStatus.Success;
            this.$store.dispatch("sim/setSimData", data);
        },
    },

    watch: {
        sensorMode() {
            this.animationActive = true;
            setTimeout(() => {
                this.animationActive = false;
            }, 300);
        },
    },

    created() {
        if (!localStorage.getItem("guide_shown")) {
            this.showGuide = true;
            localStorage.setItem("guide_shown", true);
        }

        if (this.time.to === "" && this.time.from === "")
            this.$store.dispatch("sim/initTime");

        this.getSensorsData();
    },
};
</script>

<style scoped>
.side_menu {
    position: relative;
    border-right: 1px solid black;
    padding: 30px;
    min-height: calc(100vh - 80px)
}

.show_guide_button {
    position: absolute;
    bottom: 10px;
}

.image {
    position: relative;
    top: 50px;
    opacity: 0.5;
    width: 100%;
    padding: 0 5%;
}

.image__layer {
    height: 600px;
    width: 100%;
    position: absolute;
    top: 50px;
    padding: 0 5%;
}

.main__content {
    background-color: white;
    position: relative;
}

.flashing {
    animation: flash 0.3s;
}

.side_clock {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.side_clock_time {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

@keyframes flash {
    0% {
        opacity: 1;
    }
    20% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}

@media only screen and (max-width: 720px) {
.side_menu{
    height: 100%;
}
}
</style>
