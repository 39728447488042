<template>
    <v-container class="register__container">
        <v-card class="register_form__container py-10 text-center">
            <v-card-title>
                <v-row>
                    <v-col>
                        <p
                            class="primary--text font-weight-medium text-h4 pb-5"
                        >
                            MENIoR
                        </p>
                        <p class="text-center text--secondary text-body-1">
                            {{ $t("Verify.success") }}
                        </p>
                        <v-img
                            :src="require('../../assets/verify.png')"
                            height="100"
                            contain
                        ></v-img>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-actions class="d-flex flex-column">
                <v-btn 
                    depressed 
                    color="primary" 
                    @click="redirectToLogin()"
                >
                    Go to login
                </v-btn>
            </v-card-actions>
        </v-card>
        <div class="background background_shape">
            <img
                :src="require('../../assets/background.jpg')"
                class="background background_image"
            >
        </div>
    </v-container>
</template>

<script>
// API layer Imports

export default {
    name: "Verify",

    methods: {
        redirectToLogin(){
            this.$router.replace({name: "Login"})
        }
    },
};
</script>

<style scoped>
.register__container {
    height: 85vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.register_form__container {
    width: 80%;
    max-width: 550px;
    z-index: 10;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
        rgba(0, 0, 0, 0.22) 0px 15px 12px;
    border-radius: 15px;
}

.background_shape {
    background-image: linear-gradient(
        to left top,
        #07a398,
        #009fa6,
        #009ab2,
        #0093bb,
        #008cc0,
        #3092c6,
        #4798cb,
        #599ed1,
        #80b5dc,
        #a5cce6,
        #cae2f2,
        #f0f9fe
    );
}

@media screen and (min-width: 768px) {
    .background_shape {
        clip-path: polygon(0 0, 100% 0, 100% 76%, 71% 92%, 37% 78%, 0 100%);
    }
}

@media screen and (max-height: 700px) {
    .register__container {
        height: 90%;
    }
}

.background_image {
    opacity: 0.4;
}
</style>
