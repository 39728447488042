const setToken = ( { commit }, payload) => {
    localStorage.setItem("menior_token", `${payload}`)
    commit("setToken", payload)
}

const removeToken = ( {commit}) => {
    localStorage.removeItem("menior_token");
    commit("removeToken");
}

const setUserRole = ( {commit }, payload) => {
    localStorage.setItem("menior_factory", payload);
    commit("setUserRole", payload);
}

const removeUserRole = ( { commit }) => {
    localStorage.removeItem("menior_factory");
    commit("removeUserRole")
}

const setUserId = ( {commit }, payload) => {
    localStorage.setItem("menior_user_id", payload);
    commit("setUserId", payload);
}

const removeUserId = ( { commit }) => {
    localStorage.removeItem("menior_user_id");
    commit("removeUserId")
}

export default {
    setToken,
    removeToken,
    setUserRole,
    removeUserRole,
    setUserId,
    removeUserId,
}

