import Vue from 'vue'

const SOCKET_ONOPEN  = (state, event) =>  {
    Vue.prototype.$socket = event.currentTarget
    state.isConnected = true
  }
const SOCKET_ONCLOSE = (state, event) =>   {
    state.isConnected = false
  }
const SOCKET_ONERROR = (state, event) => {
    console.error(state, event)
  }
  // default handler called for all methods
const SOCKET_ONMESSAGE = (state, message) =>   {
    state.message = message
  }
  // mutations for reconnect methods
const  SOCKET_RECONNECT = (state, count) => {
    console.info(state, count)
  }
const  SOCKET_RECONNECT_ERROR = (state)  => {
    state.socket.reconnectError = true;
  }

  export default {
    SOCKET_ONOPEN,
    SOCKET_ONCLOSE,
    SOCKET_ONERROR,
    SOCKET_ONMESSAGE,
    SOCKET_RECONNECT,
    SOCKET_RECONNECT_ERROR
}